import React, {useState} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import spmain from '../assets/spmain.jpg';
import spbarn from '../assets/spbarn.jpg';
import splawn from '../assets/splawn.jpg';
import spstreet from '../assets/spstreet.jpg';
import spmile from '../assets/spmile.jpg';

export const Siteplan = () => {
  const [isActive, setActive] = useState(false);
  
  const changeImg = (name) => {
    isActive === name ? setActive(false) : setActive(name);
  };

  return (
    <>
      <Row className="text-center justify-content-center siteplan">
        <Col lg="10" xs="11">
          <Row className="text-center justify-content-center">
            <Col lg="10" xs="11">
              <h1 className="pb-5">Moments of community can be experienced across a wide range of amenities.</h1>
            </Col>
          </Row>
        
          <Row className="justify-content-center">
            <Col className="contain">
              <div className="siteplan-image">
                <img src={spmain} alt="Images describing the community siteplan" className="img-fluid spmain" />
                <img src={splawn} alt="Images describing the community siteplan" className={`img-fluid sp splawn ${isActive === 'splawn'}`} />
                <img src={spbarn} alt="Images describing the community siteplan" className={`img-fluid sp spbarn ${isActive === 'spbarn'}`} />
                <img src={spmile} alt="Images describing the community siteplan" className={`img-fluid sp spmile ${isActive === 'spmile'}`} />
                <img src={spstreet} alt="Images describing the community siteplan" className={`img-fluid sp spstreet ${isActive === 'spstreet'}`} />
              </div>
            </Col>
          </Row>

          <Row className="align-items-start justify-content-center spcards">
            <Col className="contain">              
              <Row>
                <Col lg="6" className="py-3">
                  <div className={`spcard ${isActive === 'spstreet'}`}>
                    <p onClick={() => changeImg('spstreet')}>The Great Street is lined with trees and pathways, and is the central amenity of the community.</p>
                  </div>
                </Col>
                <Col lg="6" className="py-3">
                  <div className={`spcard ${isActive === 'spbarn'}`}>
                    <p onClick={() => changeImg('spbarn')}>The Great Barn is the community destination for fun and flexible indoor/outdoor gatherings.</p>
                  </div>
                </Col>
              
                <Col lg="6" className="py-3">
                  <div className={`spcard ${isActive === 'splawn'}`}>
                    <p onClick={() => changeImg('splawn')}>At the end of the Great Street, you’ll find the Great Lawn, a place to throw a ball, read a book or have a family picnic.</p>
                  </div>
                </Col>
                <Col lg="6" className="py-3">
                  <div className={`spcard ${isActive === 'spmile'}`}>
                    <p style={{margin: '1rem'}} onClick={() => changeImg('spmile')}>The Great Mile promotes circulation and socialization around the community, including a Para Course, and walking and biking paths.</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="text-center justify-content-center">
        <Col>
          <a href="//www.mulberryfarmsaz.com" target="_blank" className="btn rounded-pill top-button" role="button">See Our Homes</a>
        </Col>
      </Row>
    </>
  );
}

export default Siteplan;
