import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {ReactComponent as Logo} from '../assets/gs_logo.svg';

const Header = props => {
  return (
    <div className="header">
      <Row className="justify-content-center">
        <Col className="text-center pe-0">
          <Logo className="main-logo" />
        </Col>
      </Row>
    </div>
  );
};

export default Header;
