import React from 'react';
import {Row, Col} from 'react-bootstrap';

export const BottomContent = () => {
  return (
    <Row className="pb-5 text-center justify-content-center">
      <Col xl="8" lg="10" xs="11" className="contain">
        <Row>
          <Col>
            <h1>What makes a Great Street Home?</h1>
            <p>Here, great moments occur all around our charming 2- and 3-bedroom homes where front porches and private yards create opportunities to socialize.</p>
          </Col>
        </Row>
        <Row>
          <Col className="py-5">
            <span className="border-tab">&nbsp;</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="bold-italic">Timeless Farmhouse & Craftsman architecture</p>
            <p className="bold-italic">Attached garages and driveways that keep the streets uncluttered</p>
            <p className="bold-italic">Pet-friendly, private rear yards for entertaining</p>
            <p className="bold-italic">A Zoom room for working from home</p>
          </Col>
        </Row>
        <Row>
          <Col className="py-5">
            <span className="border-tab">&nbsp;</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default BottomContent;
