import './styles/app.scss';
import Layout from './components/layout';
import GSCarousel from './components/carousel';
import Header from './components/header';
import TopContent from './components/topContent';
import CardGrid from './components/cards';
import Siteplan from './components/siteplan';
import BottomContent from './components/bottomContent';
import Footer from './components/footer';

export default function App() {
  return (
    <div className="App">
      <Layout>
        <Header />
        <GSCarousel />
        <TopContent/>
        <CardGrid/>
        <Siteplan/>
        <BottomContent/>
        <Footer />
      </Layout>
    </div>
  );
}
