import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

export const GSCarousel = () => {
  return (
    <Carousel
      indicators={false}
      controls={false}
      className="gscarousel"
    >
      <Carousel.Item>
        <div className="img-1 d-block w-100 h-100"/>
      </Carousel.Item>
      <Carousel.Item>
        <div className="img-2 d-block w-100  h-100"/>
      </Carousel.Item>
      <Carousel.Item>
        <div className="img-3 d-block w-100  h-100"/>
      </Carousel.Item>
    </Carousel>
  );
};

export default GSCarousel;
