import React from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {ReactComponent as XLogo} from '../assets/x_logo_wide.svg';
import {ReactComponent as Mulberry} from '../assets/mulberry_logo_opening.svg';
import poster from '../assets/poster.jpg';

export const TopContent = () => {
  return (
    <>
    <Row className="py-5 text-center justify-content-center top-content">
      <Col xl="10" lg="10" xs="11" className="contain">
        <Row>
          <Col>
            <h1>Crafting Life&rsquo;s Moments.</h1>
          </Col>
        </Row>
        <Row>
          <Col className="py-3">
            <XLogo className="x-logo"/>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Within every neighborhood there are special moments. Like the ones that spontaneously occur on the tree-lined streets and front porches of a charming, well-planned community. They happen when people slow down and take the time to say hello and catch up with one another. It’s when they are enjoying their backyard, walking a pet or going for a bike ride at sunset in a place where families live and play and are proud to call home.</p>
            <p className="bold-italic pt-3">Welcome home to a Great Street Community.</p>
          </Col>
        </Row>
      </Col>
    </Row>

    <Row className="py-5 text-center justify-content-center top-content mulberry-farms">
      <Col xl="10" lg="10" xs="10" className="contain">
        <Row>
          <Col>
            <Mulberry/>
            <h1 style={{visibility: 'hidden', height: 0}}>Mulberry Farms<sup>&trade;</sup></h1>
            <p style={{visibility: 'hidden'}} className="blue"><strong>A GREAT STREET COMMUNITY</strong></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Through the month of December, we’ll be celebrating the Great Opening of Mulberry Farms, our new 55+ rental home community for active and engaged adults. Our charming 2- and 3-bedroom homes feature attached garages and driveways, extra storage, pet-friendly spacious backyards, and timeless Farmhouse and Craftsman architecture.</p>
            <p>Visit the link below to our flexible floorplans, see the one-of-a-kind amenities, and learn more about our community’s events and activities tailored to meet the interests and passions of our residents. We’re located at the Southeast corner of State Route 69 and Bradshaw Mountain Road in beautiful Prescott Valley, AZ.</p>
          </Col>
        </Row>
        <Row>
          <Col className="py-5 text-center">
            <div className="ratio ratio-16x9 video-container">
              <video
                controls
                poster={poster}
              >
                <source src="video.mp4" type="video/mp4" />
              </video>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className="text-center justify-content-center">
      <Col>
        <a href="//www.mulberryfarmsaz.com" target="_blank" className="btn rounded-pill top-button" role="button">See Our Homes</a>
      </Col>
    </Row>
    </>
  );

}

export default TopContent;
