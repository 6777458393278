import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

const Layout = props => {
  const {children} = props;
  return (
    <Container fluid className="">
      <Row className="">
        <Col className="p-0">
          {children}    
        </Col>
      </Row>
    </Container>
  )
}

export default Layout;
