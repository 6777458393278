import React, {useState} from 'react';
import {Row, Col, Card} from 'react-bootstrap';
import barn from '../assets/barn.jpg';
import lawn from '../assets/lawn.jpg';
import street from '../assets/street.jpg';
import mile from '../assets/mile.jpg';
import {ReactComponent as Flag} from '../assets/gs_flag_logo.svg';
import {ReactComponent as Icon1} from '../assets/icon_1.svg';
import {ReactComponent as Icon2} from '../assets/icon_2.svg';
import {ReactComponent as Icon3} from '../assets/icon_3.svg';
import {ReactComponent as Icon4} from '../assets/icon_4.svg';
import ReactCardFlip from 'react-card-flip';

export const CardGrid = () => {
  const [flipped0, setFlipped0] = useState(false);
  const [flipped1, setFlipped1] = useState(false);
  const [flipped2, setFlipped2] = useState(false);
  const [flipped3, setFlipped3] = useState(false);
  
  const closeFirst = async () => {
    setFlipped0(false);
    setFlipped1(false);
    setFlipped2(false);
    setFlipped3(false);
  }

  const cards = [
    {
      img: street,
      title: "The Great Street",
      flip: () => {closeFirst().then(() => setFlipped0(!flipped0))},
      flipped: flipped0,
      back: "Lined with trees and pathways, the Great Street is the focal point of the community. Thoughtfully placed so that each home faces the street, neighbors can get to know each other from their charming front porches.",
      icon: <Icon1/>,
    },
    {
      img: lawn,
      title: "The Great Lawn",
      flip: () => {closeFirst().then(() => setFlipped1(!flipped1))},
      flipped: flipped1,
      back: "The center of the community is a landscaped oasis of recreation. The Great Lawn provides a place to play games, talk to neighbors, and offers a common ground for family and friends to connect.",
      icon: <Icon2/>,
    },
    {
      img: mile,
      title: "The Great Mile",
      flip: () => {closeFirst().then(() => setFlipped2(!flipped2))},
      flipped: flipped2,
      back: "The community’s Great Mile is marked and measured to track walking, running and biking distances. With outdoor exercise equipment, a Para Course and bike paths, the Great Mile is 5,280 feet of encouragement.",
      icon: <Icon3/>,
    },
    {
      img: barn,
      title: "The Great Barn",
      flip: () => {closeFirst().then(() => setFlipped3(!flipped3))},
      flipped: flipped3,
      back: "With moveable furniture and game tables, as well as an operating Silo Bar and catering kitchen, the Great Barn is the perfect place to enable countless moments between residents.",
      icon: <Icon4/>,
    }
  ];

  return(
    <>
      <div className="card-container flex-column">
        <Row className="text-center justify-content-center">
          <Col lg="8" xs="10" md="10" className="contain">
            <Row xs={1} md={2} className="g-5 parent-cards">
              {cards.map((card, idx) => {
                return (
                  <Col key={idx}>
                    <ReactCardFlip 
                      isFlipped={card.flipped} 
                      flipDirection="horizontal"
                      containerStyle={{width: '100%', height: '100%'}}
                      >
                      <Card className="card-front" onClick={card.flip}>
                        <div className="card-icon">
                          {card.icon}
                        </div>
                        <Card.Body>
                          <Card.Title>{card.title}</Card.Title>
                        </Card.Body>
                        <div className="img" style={{backgroundImage: 'url('+card.img+')'}}/>
                      </Card>

                      <Card className="card-back" onClick={card.flip}>
                        <div className="card-icon">
                          {card.icon}
                        </div>
                        <Card.Body>
                          <div className="back-container">
                            <Card.Title>{card.title}</Card.Title>
                            <p>{card.back}</p>
                          </div>
                        </Card.Body>
                      </Card>
                    </ReactCardFlip>
                  </Col>
                )}
              )}
            </Row>
          </Col>
        </Row>
      </div>
      <Row>
        <Col style={{position: 'relative'}}>
          <Flag className="flag" />          
        </Col>
      </Row>
    </>
  );
}

export default CardGrid;
