import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {ReactComponent as Logo} from '../assets/footer_logo.svg';
import {ReactComponent as EhhLogo} from '../assets/ehh_logo.svg';

export const Footer = () => {
  return(
    <div className="footer p-5">
      <Row className="justify-content-around">
        <Col className="fcontain">
          <Row>
            <Col lg="4" md="12" className="text-center border-col p-3">
              <div className="footer-logo">
                <Logo/>
              </div>              
            </Col>
            <Col lg="4" md="12" className="text-center p-3 border-col">
              <div className="address">
                <h3 style={{'whiteSpace': 'nowrap'}}>Mulberry Farms</h3>
                <p><span className="nowrap">12351 E. Bradshaw Mountain Rd</span><br /><span className="nowrap">Prescott Valley, Arizona 86327</span></p>
                <p><span className="nowrap">State Route 69 and</span><br /><span className="nowrap">Bradshaw Mountain Rd</span></p>
              </div>          
            </Col>
            <Col lg="4" md="12" className="text-center p-3">
              <a href="mailto:contact@greatstreetco.com" className="btn rounded-pill" role="button">Contact Us</a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="pt-5">
          <p className="legal">
            <span className="f-logo"><EhhLogo/></span>
            <span>&copy; {new Date().getFullYear()}</span>
            The Great Street Company. All Rights Reserved.
            {/* | <a href="void(0)">Accessibility Statement</a> */}
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
